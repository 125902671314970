export const dark = {
  mainColor: '#845ec2',
  mainDarkColor: ' #444346',
  mainLightColor: '#b0a8b9',
  color: '#fff5f9',
  transitionTime: '0.75s',
  boxShadowAll: '7px 1px 15px 1px rgb(93, 94, 95)',
  baseSize: '12px',
  logoFont: '"Kaushan Script", cursive;',
  headerFont: '"Caveat", cursive',
  header2Font: '"Indie Flower", cursive',
  textFont: '"Cormorant", serif',
  textFontSansserif: '"Montserrat", sans-serif',
};
