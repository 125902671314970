import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-top: 2rem;
  @media (max-width: 648px) {
    flex-direction: column;
  }
`;

const Boxes = styled.div`
  position: relative;
  flex-basis: 48%;
  margin-top: 2rem;
  margin: 1rem;
  padding: 0.7rem;
  border-radius: 4rem;
  border: 2px solid ${({ theme }) => theme.mainColor};
`;

const Title = styled.h2`
  font-size: 1.5rem;
  /* font-style: italic; */
  font-family: ${({ theme }) => theme.headerFont};
`;

const Text = styled.p`
  font-size: 1.2rem;
  padding: 0rem 1rem 1rem 1rem;
  /* letter-spacing: 0.1rem; */
  justify-content: center;
  text-align: center;
  font-family: ${({ theme }) => theme.textFont};
`;

const About: React.FC = () => {
  return (
    <Container>
      <Boxes>
        <Title>Me</Title>
        <Text>
          I became interested in computer science when I was 7 years old. That's
          when my dad, as a computer scientist, drew me into the world of
          technology. Since then, it has been with me every step of the way.
          Later, I went to the IT Technical School and that's how I got where I
          am.
        </Text>
      </Boxes>
      <Boxes>
        <Title>My Work</Title>
        <Text>
          I work in IT support at an automotive company. I manage the computer
          network, Active Directory, camera infrastructure. My new job is to
          automate repetitive processes (mainly HR department)
        </Text>
      </Boxes>
    </Container>
  );
};

export default About;
